import { EditableGeoJsonLayer } from "@deck.gl-community/editable-layers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DrawPolygonMode, ViewMode } from "@deck.gl-community/editable-layers";
import { emptyFeatureCollection } from "../../utils/geopatialUtils";
import {
    MapEmissionRecordsListDataSourceEnum,
    MapPlumeImagesListDataSourceEnum,
    PlumeImage,
} from "../../apiClient/generated";
import * as turf from "@turf/turf";
import { useMapApiClient } from "../../hooks";
import { DataPointMapWithBin } from "./CustomTypes";
import {
    EmissionImagesLayer,
    EmissionRecordsLayer,
    PlumeOutlinesLayer,
} from "./layers/emissions";
import {
    CROSSHAIR_ICONS,
    EMISSION_COLORS,
    getBinForRateOrConcentration,
} from "./constants";
import { useMap } from "./hooks/mapState";
import { useMapData, useMapFilters } from "./hooks/mapDataAndFilters";
import { useMapDataLoader } from "./hooks/dataLoader";

const selectedFeatureIndexes: number[] = [];

/**
 * Custom hook to handle drawing and area filtering on map.
 */
export const useDrawingAndFilteringOnMap = () => {
    const [isFiltering, setIsFiltering] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [drawnShape, setDrawnShape] = useState(emptyFeatureCollection);

    // Drawing on map
    const drawingLayer = useMemo(
        () =>
            new (EditableGeoJsonLayer as any)({
                id: "drawing-layer",
                data: drawnShape,
                mode: isDrawing ? DrawPolygonMode : ViewMode,
                selectedFeatureIndexes,
                pickable: isDrawing,
                modeConfig: {
                    enableSnapping: true,
                },
                onEdit: ({ updatedData, editType }) => {
                    if (editType === "addFeature") {
                        setIsDrawing(false);
                        setDrawnShape(updatedData);
                    }
                },
            }),
        [drawnShape, isDrawing],
    );

    const hasShape = useMemo(() => {
        return (
            drawnShape && drawnShape.features && drawnShape.features.length > 0
        );
    }, [drawnShape]);

    const filterByArea = useMemo(() => {
        if (hasShape && isFiltering) {
            return drawnShape.features.find(
                (i) =>
                    i.geometry.type == "Polygon" ||
                    i.geometry.type == "MultiPolygon",
            ).geometry;
        }
    }, [drawnShape, isFiltering, hasShape]);

    return {
        drawnShape,
        drawingLayer,
        isDrawing,
        isFiltering,
        setIsFiltering,
        startDrawing: useCallback(() => setIsDrawing(true), []),
        stopDrawing: useCallback((newShape) => {
            setIsDrawing(false);
            setDrawnShape(newShape || emptyFeatureCollection);
            setIsFiltering(false);
        }, []),
        hasShape,
        filterByArea,
        selectedFilters,
        setSelectedFilters,
    };
};

export const usePublicEmissionMapData = (
    currentZoom: number,
    areaOnScreen: any,
    enabled: boolean,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        provider?: string[] | "all";
        dataSource?: MapPlumeImagesListDataSourceEnum[];
    },
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    const [emissions, setEmissions] = useState<DataPointMapWithBin[]>([]);
    const [plumeImages, setPlumeImages] = useState<PlumeImage[]>([]);

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<DataPointMapWithBin[]>>,
            filters: {
                detectionDateRangeAfter?: Date;
                detectionDateRangeBefore?: Date;
                provider?: string[] | "all";
                dataSource?: MapEmissionRecordsListDataSourceEnum[];
            },
        ) => {
            return async (areaToFetch?: any, signal?: AbortSignal) => {
                // Apply any filter operations before passing them to API client
                const apiFilters = {
                    ...filters,
                    provider: Array.isArray(filters.provider)
                        ? filters.provider.length > 0
                            ? filters.provider
                            : undefined
                        : undefined,
                };

                // Fetch page
                let response = await apiClient.mapPublicEmissionsList(
                    {
                        ...apiFilters,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                    },
                    {
                        signal,
                    },
                );
                setter((data) =>
                    data.concat(
                        response.results.map((i) => {
                            return {
                                ...i,
                                mapDotSize: getBinForRateOrConcentration(
                                    i.detectedRate / 1000,
                                    i.concentration,
                                ).size,
                            };
                        }),
                    ),
                );
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPublicEmissionsList(
                        {
                            ...apiFilters,
                            locationWithin: areaToFetch
                                ? JSON.stringify(areaToFetch)
                                : undefined,
                            cursor: parameters.get("cursor"),
                        },
                        {
                            signal,
                        },
                    );
                    setter((data) =>
                        data.concat(
                            response.results.map((i) => {
                                return {
                                    ...i,
                                    mapDotSize: getBinForRateOrConcentration(
                                        i.detectedRate / 1000,
                                        i.concentration,
                                    ).size,
                                };
                            }),
                        ),
                    );
                }
            };
        },
        [apiClient],
    );

    const fetchPlumeImagesDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<PlumeImage[]>>,
            filters: {
                detectionDateRangeAfter?: Date;
                detectionDateRangeBefore?: Date;
                provider?: string[] | "all";
                dataSource?: MapPlumeImagesListDataSourceEnum[];
            },
        ) => {
            return async (areaToFetch?: any, signal?: AbortSignal) => {
                // Apply any filter operations before passing them to API client
                const apiFilters = {
                    ...filters,
                    provider: Array.isArray(filters.provider)
                        ? filters.provider.length > 0
                            ? filters.provider
                            : undefined
                        : undefined,
                };

                // Fetch page
                let response = await apiClient.mapPublicPlumeImagesList(
                    {
                        ...apiFilters,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                    },
                    {
                        signal,
                    },
                );

                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPublicPlumeImagesList(
                        {
                            ...apiFilters,
                            locationWithin: areaToFetch
                                ? JSON.stringify(areaToFetch)
                                : undefined,
                            cursor: parameters.get("cursor"),
                        },
                        {
                            signal,
                        },
                    );
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Load emissions
    const emissionsDataLoader = useMapDataLoader({
        loadDataCallback: fetchDataFn(setEmissions, filters),
        zoomToStartFetching: 1,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all"),
        areaOnScreen,
        zoom: currentZoom,
    });

    // Load plume images
    const plumeImagesDataLoader = useMapDataLoader({
        loadDataCallback: fetchPlumeImagesDataFn(setPlumeImages, filters),
        zoomToStartFetching: 12,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all"),
        areaOnScreen,
        zoom: currentZoom,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setEmissions([]);
        setPlumeImages([]);
        emissionsDataLoader.resetState();
        plumeImagesDataLoader.resetState();
    }, [filters]);

    // Filter emissions using filterByArea
    const shownEmissions = useMemo(() => {
        if (filterByArea) {
            return emissions.filter((i) => {
                return turf.booleanPointInPolygon(
                    i.location?.coordinates,
                    filterByArea,
                );
            });
        }
        return emissions;
    }, [emissions, filterByArea]);

    // Filter plumeImages using filterByArea
    const shownPlumeImages = useMemo(() => {
        if (filterByArea) {
            return plumeImages.filter((plumeImage) => {
                return turf.booleanPointInPolygon(
                    plumeImage.center?.coordinates,
                    filterByArea,
                );
            });
        }
        return plumeImages;
    }, [plumeImages, filterByArea]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissions: shownEmissions,
        plumeImages: shownPlumeImages,
    };
};

/**
 * Custom hook to display layers based on filters and current viewstate.
 */
export const useAerscapeLayers = (
    // Infrastructure
    infrastructureLayers: any[],
    pipelineLayers: any[],
    aerialImageLayers: any[],
    // Emissions
    emissionLayers: any[],
    plumeLayers: any[],
    // Public emission data
    emissionRecordData: {
        thirdPartyPublic: DataPointMapWithBin[];
    },
    publicPlumeImagesData: PlumeImage[],
) => {
    const {
        debounced: { viewState },
    } = useMap("mainMap");
    const { filterState } = useMapFilters("mainMap");
    const zoom = useMemo(() => viewState?.zoom, [viewState]);

    // Get map selection data
    const {
        selectedContext,
        mapSettings: { plumeOpacity },
    } = useMapData("mainMap");

    const publicEmissionPlumesLayer = useMemo(() => {
        if (selectedContext.relatedPlume === null) {
            return [];
        }
        return EmissionImagesLayer(
            selectedContext.relatedPlume
                ? publicPlumeImagesData.filter(
                      (i) => i.id === selectedContext.relatedPlume,
                  )
                : publicPlumeImagesData,
            "public_plumes",
            filterState.emissions.showPublicEmissions,
            zoom,
            plumeOpacity,
        );
    }, [
        publicPlumeImagesData,
        filterState.emissions.showPublicEmissions,
        zoom,
        selectedContext,
        plumeOpacity,
    ]);

    const publicPlumeOutlines = useMemo(
        () =>
            emissionRecordData.thirdPartyPublic
                .filter(
                    (point) =>
                        point.geometry !== null &&
                        (!(
                            selectedContext.emissionRecordId ||
                            selectedContext.dataPointId
                        ) ||
                            selectedContext.dataPointId === point.id),
                )
                .map((point) => point.geometry),
        [emissionRecordData.thirdPartyPublic, selectedContext],
    );

    const publicPlumeOutlinesLayer = useMemo(() => {
        return PlumeOutlinesLayer(
            publicPlumeOutlines,
            "public_plume_outlines",
            filterState.emissions.showPublicEmissions,
            zoom,
            plumeOpacity,
        );
    }, [
        publicPlumeOutlines,
        filterState.emissions.showPublicEmissions,
        zoom,
        plumeOpacity,
    ]);

    const emissionThirdPartyPublicLayer = useMemo(() => {
        return EmissionRecordsLayer(
            emissionRecordData.thirdPartyPublic,
            filterState.emissions.showPublicEmissions,
            zoom,
            EMISSION_COLORS.thirdPartyPublic,
            CROSSHAIR_ICONS.thirdPartyPublic,
            "datapointThirdPartyPublic",
        );
    }, [emissionRecordData, filterState.emissions.showPublicEmissions, zoom]);

    return [
        aerialImageLayers,
        publicPlumeOutlinesLayer,
        publicEmissionPlumesLayer,
        ...plumeLayers,
        ...(pipelineLayers || []),
        ...infrastructureLayers,
        emissionThirdPartyPublicLayer,
        ...emissionLayers,
    ];
};
