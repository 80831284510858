import { useEmissionRecordsApiClient } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import {
    EmissionRecordView,
    EmissionStatusEnum,
    EmissioneventsListDataSourceEnum,
    EventStatusEnum,
} from "../../apiClient/generated";
import { CoordinatesField } from "../../ui/GenericFields";
import { SearchInput } from "../../ui/Inputs";
import { useMemo, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { DateTime } from "luxon";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
    DateRangeFilter,
    LocationFilterV2,
    ProviderFilter,
    RangeFilter,
    SecondaryDataSourceFilter,
} from "../DataTable/TableFilters";
import { PrimaryButton, SecondaryButton } from "../../ui/Buttons";
import { RateWithUncertaintyCell } from "./Extras";
import { DataTableV3 } from "../DataTable/DataTableV3";
import { useAtom } from "jotai";
import { tableStateFamily } from "../DataTable/state";
import { useResetAtom } from "jotai/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/sharp-light-svg-icons";

const columnHelper = createColumnHelper<EmissionRecordView>();
const baseEmissionColumns = [
    columnHelper.accessor(
        (row) => {
            return {
                detectedRate: row.dataPoint?.detectedRate,
                detectedRateUncertainty: row.dataPoint?.detectedRateUncertainty,
            };
        },
        {
            id: "detectedRate",
            meta: {
                customFilterWidget: RangeFilter,
                sortingKey: "data_point__detected_rate",
            },
            cell: (info) => {
                const { detectedRate, detectedRateUncertainty } =
                    info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={detectedRate ? detectedRate / 1000 : detectedRate}
                        uncertainty={
                            detectedRateUncertainty
                                ? detectedRateUncertainty / 1000
                                : detectedRateUncertainty
                        }
                        unit="kg/h"
                    />
                );
            },
            header: () => "Detection rate",
        },
    ),
    columnHelper.accessor(
        (row) => {
            return {
                concentration: row.dataPoint?.concentration,
                concentrationUncertainty:
                    row.dataPoint?.concentrationUncertainty,
            };
        },
        {
            id: "concentration",
            meta: {
                customFilterWidget: RangeFilter,
                sortingKey: "data_point__concentration",
            },
            cell: (info) => {
                const { concentration, concentrationUncertainty } =
                    info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={concentration}
                        uncertainty={concentrationUncertainty}
                        unit="ppm*m"
                    />
                );
            },
            header: () => "Concentration",
        },
    ),
    columnHelper.accessor(
        (row) => {
            return {
                infrastructureData: row.infrastructureData,
                matches: row.matches,
            };
        },
        {
            id: "infrastructure",
            cell: (info) => {
                const value = info.getValue();
                return (
                    <div className="overflow-hidden truncate">
                        {value.infrastructureData ? (
                            value.infrastructureData.siteName
                        ) : value.matches && value.matches.length > 1 ? (
                            <span
                                className="flex gap-1 items-center"
                                title={`Multiple infrastructures matched with this emission: ${value.matches
                                    .map((i) => i.name)
                                    .join(", ")}.`}
                            >
                                Multiple Possible Matches
                                <QuestionMarkCircleIcon className="h-4" />
                            </span>
                        ) : (
                            <span className="italic">
                                No associated infrastructure.
                            </span>
                        )}
                    </div>
                );
            },
            header: () => <span>Infrastructure</span>,
            enableColumnFilter: false,
            enableSorting: false,
        },
    ),
    columnHelper.accessor("providerName", {
        id: "provider",
        cell: (info) => info.getValue(),
        header: () => "Provider",
        enableSorting: false,
        meta: {
            customFilterWidget: ProviderFilter,
        },
    }),
    columnHelper.accessor((row) => row.dataPoint?.secondaryDataSource, {
        id: "secondaryDataSource",
        meta: {
            sortingKey: "data_point__secondary_data_source",
            customFilterWidget: SecondaryDataSourceFilter,
        },
        cell: (info) => info.getValue() || "-",
        header: () => "Secondary Data Source",
        enableSorting: false,
        enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.dataPoint?.detectionTimestamp, {
        id: "detectionTimestamp",
        meta: {
            sortingKey: "data_point__detection_timestamp",
            filteringKey: "detectionDateRange",
            customFilterWidget: DateRangeFilter,
        },
        cell: (info) =>
            DateTime.fromJSDate(info.renderValue()).toLocaleString(
                DateTime.DATETIME_SHORT,
            ),
        header: () => "Detection Date (UTC)",
        enableColumnFilter: true,
    }),
    columnHelper.accessor("createdAt", {
        id: "createdAt",
        meta: {
            customFilterWidget: DateRangeFilter,
        },
        cell: (info) => {
            if (!info.getValue()) {
                return "-";
            }
            return DateTime.fromJSDate(info.getValue()).toLocaleString(
                DateTime.DATETIME_SHORT,
            );
        },
        header: () => "Import Date (UTC)",
    }),
    columnHelper.accessor((row) => row.dataPoint?.location, {
        id: "location",
        cell: (info) => (
            <CoordinatesField
                coordinates={info.getValue()?.coordinates}
                allowCopy
            />
        ),
        header: () => "Location",
        enableColumnFilter: true,
        enableSorting: false,
        meta: {
            customFilterWidget: LocationFilterV2,
        },
    }),
    columnHelper.accessor(
        (row) => ({
            matches: row.matches,
            infrastructure: row.infrastructureData,
        }),
        {
            id: "operatorIds",
            cell: (info) => {
                const data = info.getValue();
                if (data.infrastructure) {
                    if (
                        data.infrastructure.extraData &&
                        data.infrastructure.extraData["Operator Unique ID 1"]
                    ) {
                        return data.infrastructure.extraData[
                            "Operator Unique ID 1"
                        ];
                    }
                    return "";
                }

                if (Array.isArray(data.matches)) {
                    const infraWithOperatorIds = data.matches.filter(
                        (i) =>
                            i.extraData &&
                            i.extraData["Operator Unique ID 1"]?.trim(),
                    );
                    if (infraWithOperatorIds.length > 1) {
                        return (
                            <span
                                className="flex gap-1 items-center italic text-gray-500"
                                title={infraWithOperatorIds
                                    .map(
                                        (i) =>
                                            i.extraData["Operator Unique ID 1"],
                                    )
                                    .join(", ")}
                            >
                                Multiple
                                <QuestionMarkCircleIcon className="h-4" />
                            </span>
                        );
                    }
                    if (
                        infraWithOperatorIds.length == 1 &&
                        infraWithOperatorIds[0].extraData
                    ) {
                        return infraWithOperatorIds[0].extraData[
                            "Operator Unique ID 1"
                        ];
                    }
                }
                return "";
            },
            header: () => "Operator Unique ID",
            enableColumnFilter: false,
            enableSorting: false,
        },
    ),
];

interface ApiDataTable {
    showStatus?: boolean;
    showActions?: boolean;
    dataSource?: EmissioneventsListDataSourceEnum[];
    emissionStatus?: EmissionStatusEnum[];
    eventStatus?: EventStatusEnum[];
    onClickRow?: (id: string | number) => void;
}

export const EmissionApiDataTable = (props: ApiDataTable) => {
    const { dataSource, eventStatus, emissionStatus } = props;
    const apiClient = useEmissionRecordsApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // Table state
    const tableAtom = useMemo(
        () =>
            tableStateFamily({
                tableId: `newEmissions-${dataSource}`,
                initialState: {
                    sorting: {
                        id: "data_point__detection_timestamp",
                        desc: true,
                    },
                },
            }),
        [dataSource],
    );
    useAtom(tableAtom);
    const resetTableState = useResetAtom(tableAtom);

    const fetchFunction = async (props: any) => {
        return await apiClient.emissionRecordsList(props);
    };

    const columns = useMemo(() => {
        const cols = [
            props.showActions &&
                columnHelper.display({
                    id: "actions",
                    header: "Actions",
                    cell: (info) => (
                        <PrimaryButton
                            variant="sm"
                            onClick={() =>
                                props.onClickRow(info.row.original.id)
                            }
                        >
                            {info.row.original.emissionStatus === "REJECTED"
                                ? "View"
                                : "Review"}
                        </PrimaryButton>
                    ),
                    enableColumnFilter: false,
                }),
            ...baseEmissionColumns,
        ];
        return cols.filter((c) => !!c);
    }, [props]);

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <SearchInput
                    containerClassName="w-96"
                    placeholder="Search by infrastructure name..."
                    value={filterSearch}
                    onChange={(newValue) => setFilterSearch(newValue)}
                />
                <div className="flex gap-2 items-center">
                    <SecondaryButton variant="sm" onClick={resetTableState}>
                        <FontAwesomeIcon
                            icon={faFilterCircleXmark}
                            className="w-4"
                        />
                        Clear filters
                    </SecondaryButton>
                </div>
            </div>

            {/* Data table */}
            <DataTableV3<EmissionRecordView>
                dataName={`newEmissions-${props.dataSource}`}
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                    dataSource,
                    eventStatus,
                    emissionStatus,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={true}
                onClickRow={!props.showActions && props.onClickRow}
            />
        </div>
    );
};
