/**
 * NOTE: Refactor in progress.
 *
 * Instead of building all the secondary maps here, we
 * should instead build composable components that can be
 * re-used accross different map implementations.
 */

import {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import DeckGL, { DeckGLRef } from "@deck.gl/react";
import Map, { ScaleControl } from "react-map-gl/maplibre";
import {
    AdminInfrastructureImportItemLocation,
    Infrastructure,
    InfrastructureMapList,
    AdminInfrastructureImportItemPipelineShape,
    AdminInfrastructureImportItemShape,
    EmissionRecordMap,
    SimpleInfrastructure,
} from "../../apiClient/generated";
import { useInfrastructureApiClient, useMapApiClient } from "../../hooks";
import {
    InfrastructureLayer,
    InfrastructureOnlyLayerColorMap,
} from "./components/layers/Infrastructure/infrastructure";
import { GeoJsonLayer } from "@deck.gl/layers";
import { MapPinIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import * as turf from "@turf/turf";
import { basemaps } from "./basemaps";
import { EditableGeoJsonLayer } from "@deck.gl-community/editable-layers";
import { DrawPolygonMode, ViewMode } from "@deck.gl-community/editable-layers";
import {
    createFeatureCollection,
    emptyFeatureCollection,
} from "../../utils/geopatialUtils";
import type { FeatureCollection } from "geojson";

const selectedFeatureIndexes: number[] = [];

const getMapStyle = (zoom: number): any => {
    return zoom > 18 ? basemaps.osm.mapStyle : basemaps.esri.mapStyle;
};

interface InfrastructureTooltipProps {
    infrastructureId: string;
}

const InfrastructureTooltip = (props: InfrastructureTooltipProps) => {
    const apiClient = useInfrastructureApiClient();
    const [data, setData] = useState<Infrastructure>();

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiClient.infrastructureListRetrieve({
                id: props.infrastructureId,
            });
            setData(response);
        };
        fetchData();
    }, [props.infrastructureId, apiClient]);

    return (
        <div className="px-4 py-2 text-sm rounded-lg bg-white">
            {data ? (
                <>
                    {data.name} - {data.infraType} {data.equipmentType}
                </>
            ) : (
                "Loading..."
            )}
        </div>
    );
};

interface MiniMapBaseProps {
    mapLayers: any[];
    getTooltip?: (info: any) => string | undefined;
    children?: ReactNode;
    initialLatitude?: number;
    initialLongitude?: number;
    initialZoom?: number;
    onClick?: (infrastructure?: SimpleInfrastructure) => void;
    onHover?: (infrastructureId?: string) => void;
}

// TODO: Make other minimap implementation reuse this and improve
// it to make it more flexible
export const MiniMapBase = (props: MiniMapBaseProps) => {
    // Mini map data states
    const deckRef = useRef<null | DeckGLRef>(null);
    const [viewstate, setViewstate] = useState({
        longitude: props.initialLatitude || -98.58,
        latitude: props.initialLongitude || 39.82,
        zoom: props.initialZoom || 3,
        bearing: 0,
        pitch: 0,
    });

    return (
        <div>
            <DeckGL
                ref={deckRef}
                viewState={viewstate}
                layers={props.mapLayers}
                onViewStateChange={(e) =>
                    setViewstate({
                        ...viewstate,
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                    })
                }
                onClick={(info) => {
                    if (props.onClick && info.layer) {
                        if (info.layer.id === "infrastructure") {
                            props.onClick(info.object?.properties);
                        }
                    }
                }}
                onHover={(info) => {
                    if (props.onHover && info.layer) {
                        if (info.layer.id === "infrastructure") {
                            props.onHover(info.object?.properties.id);
                        }
                    }
                }}
                controller={true}
                getCursor={({ isHovering, isDragging }) =>
                    isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
                }
                getTooltip={props.getTooltip}
            >
                <Map
                    id="miniMap"
                    style={{ height: "100vh" }}
                    mapStyle={getMapStyle(viewstate.zoom)}
                >
                    <ScaleControl />
                </Map>
            </DeckGL>

            {props.children}

            {/* Zoom controls */}
            <div className="absolute right-3 bottom-10 z-0 text-2xl">
                <button
                    className="rounded-t-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={(e) => {
                        e.preventDefault();
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom + 1,
                        });
                    }}
                >
                    +
                </button>

                <hr />

                <button
                    className="rounded-b-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={(e) => {
                        e.preventDefault();
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom - 1,
                        });
                    }}
                >
                    -
                </button>
            </div>
        </div>
    );
};

/**
 * TODO: Implement better map fetching infrastructure.
 *
 * Use react query, share data and cache map information.
 */

interface EmissionMiniMapProps {
    emissionId: string;
    onClick?: (infrastructureId: string) => void;
}

/**
 * Emission Mini Map
 *
 * Used to display one emission, its plumes and nearby infrastructure.
 * Optional `onClick` parameter that return clicked infrastructure.
 *
 * @param props
 * @returns
 */
export const EmissionMiniMap = (props: EmissionMiniMapProps) => {
    // API clients
    const mapApiClient = useMapApiClient();

    // Mini map data states
    const deckRef = useRef<null | DeckGLRef>(null);
    // TODO: improve tying for hoverInfo
    const [hoverInfo, setHoverInfo] = useState<any>();
    const [emissionData, setEmissionData] = useState<EmissionRecordMap>();
    const [nearbyInfrastructure, setNearbyInfrastructure] =
        useState<InfrastructureMapList[]>();
    const [showLayer, setShowLayer] = useState({
        emission: true,
        raster: true,
    });

    const loadData = async () => {
        const infrastructure = await mapApiClient.mapInfrastructureList({
            nearEmission: props.emissionId,
        });
        setNearbyInfrastructure(infrastructure.results);
        const emission = await mapApiClient.mapEmissionRecordsRetrieve({
            id: props.emissionId,
        });
        setEmissionData(emission);
        setViewstate({
            ...viewstate,
            latitude: emission.location.coordinates[1],
            longitude: emission.location.coordinates[0],
            zoom: 16,
        });
    };

    useEffect(() => {
        loadData();
    }, [props.emissionId]);

    const [viewstate, setViewstate] = useState({
        longitude: -98.58,
        latitude: 39.82,
        zoom: 4,
        bearing: 0,
        pitch: 0,
    });

    const mapLayers = useMemo(() => {
        const layers = [];
        if (nearbyInfrastructure) {
            layers.push(
                InfrastructureLayer({
                    data: nearbyInfrastructure,
                    enabledLayers: ["infrastructure"],
                    onHover: (info) => setHoverInfo(info),
                }),
            );
        }
        if (emissionData) {
            layers.push(
                new GeoJsonLayer({
                    id: props.emissionId,
                    data: createFeatureCollection([emissionData]),
                    pointType: "circle",
                    filled: true,
                    visible: showLayer.emission,
                    getPointRadius: 8,
                    pointRadiusUnits: "pixels",
                    lineWidthUnits: "pixels",
                    stroked: true,
                    pickable: false,
                    getFillColor: [255, 0, 0, 255],
                    autoHighlight: true,
                    getLineWidth: 3,
                    getLineColor: [255, 255, 255, 0],
                }),
            );
        }
        return layers;
    }, [nearbyInfrastructure, emissionData, showLayer]);

    const onClick = useCallback((event) => {
        if (deckRef.current && props.onClick) {
            // We need to calculate the x and y coordinates relative to the DeckGL
            // container instance for pick operations to properly work.
            const targetBounds = event.target.getBoundingClientRect();
            const pickInfo = deckRef.current.pickObject({
                x: event.clientX - targetBounds.x,
                y: event.clientY - targetBounds.y,
            });

            if (pickInfo) {
                props.onClick(pickInfo.object.properties.id);
            }
        }
    }, []);

    return (
        <div onClick={onClick}>
            <DeckGL
                ref={deckRef}
                viewState={viewstate}
                layers={mapLayers}
                onViewStateChange={(e) =>
                    setViewstate({
                        ...viewstate,
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                    })
                }
                controller={true}
                getCursor={({ isHovering, isDragging }) =>
                    isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
                }
            >
                <Map
                    id="miniMap"
                    style={{ height: "100vh" }}
                    mapStyle={getMapStyle(viewstate.zoom)}
                >
                    <ScaleControl />
                </Map>
            </DeckGL>

            {/* Zoom controls */}
            <div className="absolute right-3 bottom-8 z-0 text-2xl">
                <button
                    className="rounded-t-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={() => {
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom + 1,
                        });
                    }}
                >
                    +
                </button>

                <hr />

                <button
                    className="rounded-b-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={() => {
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom - 1,
                        });
                    }}
                >
                    -
                </button>
            </div>

            {hoverInfo && hoverInfo.object && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 1,
                        pointerEvents: "none",
                        left: hoverInfo.x + 10,
                        top: hoverInfo.y + 10,
                    }}
                >
                    <InfrastructureTooltip
                        infrastructureId={hoverInfo.object.properties.id}
                    />
                </div>
            )}
            <div className="bg-white absolute right-0 m-4 px-4 py-2 rounded-lg">
                <p>
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={showLayer.emission}
                        onChange={() =>
                            setShowLayer({
                                ...showLayer,
                                emission: !showLayer.emission,
                            })
                        }
                    />
                    Emission
                </p>
                <p>
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={showLayer.raster}
                        onChange={() =>
                            setShowLayer({
                                ...showLayer,
                                raster: !showLayer.raster,
                            })
                        }
                    />
                    Plume
                </p>
            </div>
        </div>
    );
};

interface DrawableMiniMapProps {
    point?: AdminInfrastructureImportItemLocation;
    onChangePoint: (newPoint: AdminInfrastructureImportItemLocation) => void;
    shape:
        | AdminInfrastructureImportItemShape
        | AdminInfrastructureImportItemPipelineShape;
    onChangeShape: (
        newShape:
            | AdminInfrastructureImportItemShape
            | AdminInfrastructureImportItemPipelineShape,
    ) => void;
}

export const DrawableMiniMap = (props: DrawableMiniMapProps) => {
    // Mini map data states
    const deckRef = useRef<null | DeckGLRef>(null);

    // Drawing control state
    const [pickingPoint, setPickingPoint] = useState(false);
    const [viewstate, setViewstate] = useState({
        longitude: -98.58,
        latitude: 39.82,
        zoom: 11,
        bearing: 0,
        pitch: 0,
    });

    // Drawing on map
    const [isDrawing, setIsDrawing] = useState(false);
    const drawLayer = useMemo(
        () =>
            new (EditableGeoJsonLayer as any)({
                id: "drawing-layer",
                data: emptyFeatureCollection,
                mode: isDrawing ? DrawPolygonMode : ViewMode,
                selectedFeatureIndexes,
                pickable: isDrawing,
                modeConfig: {
                    enableSnapping: true,
                },
                onEdit: ({ updatedData, editType }) => {
                    if (editType === "addFeature") {
                        setIsDrawing(false);
                        props.onChangeShape(updatedData.features[0].geometry);
                    }
                },
            }),
        [props.shape, isDrawing, setIsDrawing],
    );

    // Compute map layers
    const mapLayers = useMemo(() => {
        const layers = [];
        const geojson = {
            type: "FeatureCollection",
            features: [],
        };

        // If a point is defined
        if (props.point) {
            geojson.features.push({
                type: "Feature",
                geometry: props.point,
                properties: {},
            });
            setViewstate({
                ...viewstate,
                longitude: props.point.coordinates[0],
                latitude: props.point.coordinates[1],
            });
        }

        // If there's a shape
        if (props.shape) {
            geojson.features.push({
                type: "Feature",
                geometry: props.shape,
                properties: {},
            });

            // Shape without a point, center on shape
            if (!props.point) {
                const centroid = turf.centroid(props.shape);
                setViewstate({
                    ...viewstate,
                    longitude: centroid.geometry.coordinates[0],
                    latitude: centroid.geometry.coordinates[1],
                });
            }
        }

        // Add layers to map
        layers.push(
            new GeoJsonLayer({
                id: "geometries",
                // FIXME: typing now included in deck.gl
                data: geojson as unknown as any,
                pointType: "circle",
                filled: true,
                visible: true,
                getPointRadius: 8,
                pointRadiusUnits: "pixels",
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: true,
                getFillColor: [0, 0, 0],
                autoHighlight: true,
                getLineWidth: 3,
                getLineColor: [255, 255, 255, 255],
            }),
        );

        if (isDrawing) {
            layers.push(drawLayer);
        }

        return layers;
    }, [props.shape, props.point, isDrawing]);

    return (
        <div>
            <DeckGL
                ref={deckRef}
                viewState={viewstate}
                layers={mapLayers}
                onViewStateChange={(e) =>
                    setViewstate({
                        ...viewstate,
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                    })
                }
                onClick={(event) => {
                    if (pickingPoint) {
                        props.onChangePoint({
                            type: "Point",
                            coordinates: event.coordinate,
                        });
                        setPickingPoint(false);
                    }
                    // TODO: Implement Drawing capabilities.
                }}
                controller={true}
                getCursor={({ isHovering, isDragging }) =>
                    isDrawing || pickingPoint
                        ? "crosshair"
                        : isDragging
                          ? "grabbing"
                          : isHovering
                            ? "pointer"
                            : "grab"
                }
            >
                <Map
                    id="miniMap"
                    style={{ height: "100vh" }}
                    mapStyle={getMapStyle(viewstate.zoom)}
                >
                    <ScaleControl />
                </Map>
            </DeckGL>

            {/* Zoom controls */}
            <div className="absolute right-3 bottom-8 z-0 text-2xl">
                <button
                    className="rounded-t-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={() => {
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom + 1,
                        });
                    }}
                >
                    +
                </button>

                <hr />

                <button
                    className="rounded-b-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={() => {
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom - 1,
                        });
                    }}
                >
                    -
                </button>
            </div>
            <div className="bg-white absolute right-0 m-4 rounded-lg">
                {!isDrawing && (
                    <button
                        className={`
                            flex items-center justify-center
                            rounded-lg w-10 h-10 bg-white hover:bg-slate-200
                        `}
                        onClick={() => {
                            setPickingPoint(!pickingPoint);
                        }}
                    >
                        {pickingPoint ? (
                            <XMarkIcon className="h-5 w-5 text-red-600" />
                        ) : (
                            <MapPinIcon className="h-5 w-5" />
                        )}
                    </button>
                )}
                {!pickingPoint && (
                    <button
                        className={`
                        flex items-center justify-center
                        rounded-lg w-10 h-10 bg-white hover:bg-slate-200
                    `}
                        onClick={() => {
                            setIsDrawing(!isDrawing);
                        }}
                        disabled={pickingPoint}
                    >
                        {isDrawing ? (
                            <XMarkIcon className="h-5 w-5 text-red-600" />
                        ) : (
                            <PencilSquareIcon className="h-5 w-5" />
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

interface GeoJSONInfrastructurePreviewMiniMapProps {
    // TODO: Type this
    geojson?: FeatureCollection<any>;
    onClick?: (infrastructureId?: string | number) => void;
    onHover?: (infrastructureId?: string | number) => void;
}

export const GeoJSONInfrastructurePreviewMiniMap = (
    props: GeoJSONInfrastructurePreviewMiniMapProps,
) => {
    // Mini map data states
    const deckRef = useRef<null | DeckGLRef>(null);
    const [viewstate, setViewstate] = useState({
        longitude: -98.58,
        latitude: 39.82,
        zoom: 5,
        bearing: 0,
        pitch: 0,
    });

    const mapLayers = useMemo(() => {
        if (!props.geojson || props.geojson.features.length < 1) {
            return [];
        }

        // Calculate center of data
        const center = turf.centerOfMass(props.geojson);
        if (center) {
            setViewstate({
                ...viewstate,
                longitude: center.geometry.coordinates[0],
                latitude: center.geometry.coordinates[1],
                zoom: 12,
            });
        }

        // Add color properties to JSON
        const geojson = { ...props.geojson };
        geojson.features.forEach((item) => {
            const infraType = item.properties.infra_type || "";
            const colorMap = InfrastructureOnlyLayerColorMap[infraType];

            if (item.geometry.type === "Polygon") {
                item.properties.fillColor = [0, 0, 0, 0];
                item.properties.lineColor = colorMap?.fillColor || [
                    0, 0, 0, 255,
                ];
                item.properties.lineWidth = colorMap?.lineWidth || 3;
            } else {
                item.properties.fillColor = colorMap?.fillColor || [
                    0, 0, 0, 255,
                ];
                item.properties.lineColor = [255, 255, 255, 255];
                item.properties.lineWidth = colorMap?.lineWidth || 3;
            }
            item.properties.pointRadius = colorMap?.pointRadius || 8;
        });

        return [
            new GeoJsonLayer({
                id: "infrastructure",
                data: geojson,
                filled: true,
                visible: true,
                stroked: true,
                pickable: true,
                pointRadiusUnits: "pixels",
                lineWidthUnits: "pixels",
                getPointRadius: (item) => item.properties.pointRadius,
                getFillColor: (item) => item.properties.fillColor,
                autoHighlight: true,
                getLineWidth: (item) => item.properties.lineWidth,
                getLineColor: (item) => item.properties.lineColor,
            }),
        ];
    }, [props.geojson]);

    return (
        <div>
            <DeckGL
                ref={deckRef}
                viewState={viewstate}
                layers={mapLayers}
                onViewStateChange={(e) =>
                    setViewstate({
                        ...viewstate,
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                    })
                }
                controller={true}
                getCursor={({ isHovering, isDragging }) =>
                    isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
                }
                onClick={(info) => {
                    if (props.onClick && info.layer) {
                        if (info.layer.id === "infrastructure") {
                            props.onClick(info.object?.properties.id);
                        }
                    }
                }}
                onHover={(info) => {
                    if (props.onHover && info.layer) {
                        if (info.layer.id === "infrastructure") {
                            props.onHover(info.object?.properties.id);
                        }
                    }
                }}
                getTooltip={({ object }) => {
                    if (!object || !object.properties) {
                        return;
                    }

                    let str = "";
                    if (object.properties.facility_name) {
                        str = object.properties.facility_name;
                    }
                    if (object.properties.equipment_type) {
                        str = `${str}: ${object.properties.equipment_type}`;
                    }
                    if (object.properties.infra_type) {
                        str = `${str} (${object.properties.infra_type
                            .replace("_", " ")
                            .toLowerCase()})`;
                    }

                    return str;
                }}
            >
                <Map
                    id="miniMap"
                    style={{ height: "100vh" }}
                    mapStyle={getMapStyle(viewstate.zoom)}
                >
                    <ScaleControl />
                </Map>
            </DeckGL>

            {/* Map legend */}
            <div className="absolute right-2 top-2 z-0 text-2xl p-2 bg-white rounded-lg">
                <ul className="list-inside text-xs">
                    {Object.entries(InfrastructureOnlyLayerColorMap).map(
                        ([key, value]) => {
                            return (
                                <li className="flex items-center capitalize">
                                    <div
                                        className="rounded-full h-3 w-3 mr-1"
                                        style={{
                                            background: `rgb(${value.fillColor[0]},${value.fillColor[1]},${value.fillColor[2]})`,
                                        }}
                                    />
                                    {key.replace("_", " ").toLowerCase()}
                                </li>
                            );
                        },
                    )}
                    <li className="flex items-center capitalize">
                        <div className="rounded-full h-3 w-3 mr-1 bg-black" />
                        Unknown / unset
                    </li>
                </ul>
            </div>

            {/* Zoom controls */}
            <div className="absolute right-3 bottom-8 z-0 text-2xl">
                <button
                    className="rounded-t-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={() => {
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom + 1,
                        });
                    }}
                >
                    +
                </button>

                <hr />

                <button
                    className="rounded-b-lg w-10 h-10 bg-white hover:bg-slate-200"
                    onClick={() => {
                        setViewstate({
                            ...viewstate,
                            zoom: viewstate.zoom - 1,
                        });
                    }}
                >
                    -
                </button>
            </div>
        </div>
    );
};
