import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ROUTES } from "./routes";
import { ErrorPage } from "./components/ErrorPage";
import { LogoutPage } from "./routes/LogoutPage";
import { LoginPageLayout } from "./routes/LoginPageLayout";
import { DashboardPage } from "./routes/DashboardPage";
import { InfrastructurePage } from "./routes/InfrastructurePage";
import { DataUploadsPage } from "./routes/DataUploadsPage";
import { DataDownloadPage } from "./routes/DataDownloadPage";
import { ResetPassword } from "./components/ResetPassword/ResetPassword";
import { TwoStepLogin } from "./components/TwoStepLogin/TwoStepLogin";
import { CookieBanner } from "./components/CookieBanner";
import { InfrastructureImportPage } from "./routes/InfrastructureImportPage";
import { InfrastructureBuilderPage } from "./routes/InfrastructureBuilder";
import { AdminDashboardPage } from "./routes/admin/AdminDashboardPage";
import { AdminInfrastructureImportPage } from "./routes/admin/AdminInfrastructureImportPage";
import { AdminInfrastructureImportApprovalPage } from "./components/InfrastructureBuilder/AdminView/AdminView";
import { Licensing } from "./routes/static/Licensing";
import { TermsOfService } from "./routes/static/TermsOfService";
import { PrivacyPolicy } from "./routes/static/PrivacyPolicy";
import { SupportPage } from "./routes/static/SupportPage";
import { AdminDjangoAdminPage } from "./routes/admin/AdminDjangoAdminPage";
import { registerLoaders } from "@loaders.gl/core";
import { GeoTiffLoader } from "./components/MapV2/utils/GeotiffLoader";
import { MapPage } from "./routes/MapPage";
import { AdminDataImportPage } from "./routes/admin/AdminDataImportPage";
import { SettingsHomePage } from "./routes/SettingsHomePage";
import { NotificationSettingsPage } from "./routes/NotificationSettingsPage";
import { NotificationChannelConfirmation } from "./routes/NotificationChannelConfirmation";
import { MainDashboardContainer } from "./routes/dashboards/MainDashboardPage";
import { ThirdPartyEmissionDashboardsPage } from "./routes/dashboards/EmissionDashboardsPage";
import { SelfReportedEmissionDashboardsPage } from "./routes/dashboards/SelfReportedDashboardsPage";
import { AdminStatsPage } from "./routes/admin/AdminStatsPage";
import "maplibre-gl/dist/maplibre-gl.css";
import { SSOComplete } from "./components/TwoStepLogin/SSOComplete";

registerLoaders(GeoTiffLoader);

const ErrorBoundaryLayout = () => (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <Outlet />
    </Sentry.ErrorBoundary>
);

const router = createBrowserRouter([
    {
        element: <ErrorBoundaryLayout />,
        children: [
            {
                path: ROUTES.LOGIN,
                element: (
                    <LoginPageLayout>
                        <TwoStepLogin />
                    </LoginPageLayout>
                ),
            },
            {
                path: ROUTES.SSO_COMPLETE,
                element: (
                    <LoginPageLayout>
                        <SSOComplete />
                    </LoginPageLayout>
                ),
            },
            {
                path: ROUTES.PASSWORD_RESET,
                element: (
                    <LoginPageLayout>
                        <ResetPassword />
                    </LoginPageLayout>
                ),
            },
            {
                path: ROUTES.NOTIFICATION_CHANNEL_VALIDATION,
                element: (
                    <LoginPageLayout>
                        <NotificationChannelConfirmation />
                    </LoginPageLayout>
                ),
            },
            {
                path: ROUTES.LOGOUT,
                element: <LogoutPage />,
            },
            {
                path: ROUTES.ADMIN_DASHBOARD,
                element: <AdminDashboardPage />,
                children: [
                    {
                        path: ROUTES.ADMIN_INFRASTRUCURE_IMPORT,
                        element: <AdminInfrastructureImportPage />,
                    },
                    {
                        path: ROUTES.ADMIN_INFRASTRUCURE_IMPORT_APPROVAL_VIEW,
                        element: <AdminInfrastructureImportApprovalPage />,
                    },
                    {
                        path: ROUTES.ADMIN_DATA_IMPORT,
                        element: <AdminDataImportPage />,
                    },
                    {
                        path: ROUTES.ADMIN_STATS,
                        element: <AdminStatsPage />,
                    },
                    {
                        path: ROUTES.ADMIN_DJANGO_ADMIN,
                        element: <AdminDjangoAdminPage />,
                    },
                ],
            },
            {
                path: ROUTES.ROOT,
                element: <DashboardPage />,
                children: [
                    {
                        path: ROUTES.MAP,
                        element: <MapPage />,
                    },
                    {
                        path: ROUTES.INFRASTRUCURE,
                        element: <InfrastructurePage />,
                    },
                    {
                        path: ROUTES.INFRASTRUCURE_IMPORT,
                        element: <InfrastructureImportPage />,
                    },
                    {
                        path: ROUTES.INFRASTRUCURE_BUILDER,
                        element: <InfrastructureBuilderPage />,
                    },
                    {
                        path: ROUTES.DATAUPLOADS,
                        element: <DataUploadsPage />,
                    },
                    {
                        path: ROUTES.DATADOWNLOAD,
                        element: <DataDownloadPage />,
                    },
                    {
                        path: ROUTES.LICENSING,
                        element: <Licensing />,
                    },
                    {
                        path: ROUTES.SUPPORT,
                        element: <SupportPage />,
                    },
                    {
                        path: ROUTES.TERMS_OF_SERVICE,
                        element: <TermsOfService />,
                    },
                    {
                        path: ROUTES.PRIVACY_POLICY,
                        element: <PrivacyPolicy />,
                    },
                    {
                        path: ROUTES.SETTINGS_HOME,
                        element: <SettingsHomePage />,
                    },
                    {
                        path: ROUTES.SETTINGS_NOTIFICATIONS,
                        element: <NotificationSettingsPage />,
                    },
                    {
                        path: ROUTES.DASHBOARD,
                        element: <MainDashboardContainer />,
                        children: [
                            {
                                path: ROUTES.DASHBOARD_THIRD_PARTY,
                                element: <ThirdPartyEmissionDashboardsPage />,
                            },
                            {
                                path: ROUTES.DASHBOARD_SELF_REPORTED,
                                element: <SelfReportedEmissionDashboardsPage />,
                            },
                            {
                                path: ROUTES.DASHBOARD_EPA_SEP,
                                element: null,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

function App() {
    return (
        <>
            <CookieBanner />
            <RouterProvider router={router} />
        </>
    );
}

export default App;
