import {
    faArrowRight,
    faChevronDoubleLeft,
    faChevronDoubleRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useCallback, useMemo } from "react";
import { useMapFilters } from "../../hooks/mapDataAndFilters";
import { ValidatingDateInput } from "../../../../ui/Inputs";

interface ButtonProps {
    onClick?: () => void;
    children: ReactNode;
    active?: boolean;
    disabled?: boolean;
    className?: string;
}

const Button = (props: ButtonProps) => (
    <button
        onClick={() => !props.disabled && props.onClick()}
        disabled={props.disabled}
        className={`
            flex text-sm items-center justify-center border
            ${
                props.disabled
                    ? "text-gray bg-gray-200"
                    : props.active
                      ? "bg-ae-blue-550 text-white border-ae-blue-550 cursor-default"
                      : "bg-white hover:bg-ae-gray-100 border-ae-gray-250"
            }
            ${props.className}
        `}
    >
        {props.children}
    </button>
);

export const DateRangeControl = () => {
    const { filterState, updateMapFilterState } = useMapFilters("mainMap");
    /**
     * Memoize dates from state to avoid unecessary rerenders.
     */
    const startDateFilter = useMemo(
        () => filterState.emissions.startDateFilter,
        [filterState.emissions.startDateFilter],
    );
    const endDateFilter = useMemo(
        () => filterState.emissions.endDateFilter,
        [filterState.emissions.endDateFilter],
    );

    /**
     * Handlers for changing start and end dates
     *
     */
    const handleChangeStartDate = useCallback(
        (v) => {
            updateMapFilterState((d) => {
                d.emissions.startDateFilter = v;
            });
        },
        [updateMapFilterState],
    );
    const handleChangeEndDate = useCallback(
        (v) => {
            updateMapFilterState((d) => {
                d.emissions.endDateFilter = v;
            });
        },
        [updateMapFilterState],
    );

    /**
     * Compute number of approximate months between the two dates.
     *
     * This is used to highlight the range shortcut buttons at the
     * top, even if the dates are a little bit off.
     */
    const monthsBetweenDates = useMemo(() => {
        const d1 = new Date(startDateFilter).getTime();
        const d2 = new Date(endDateFilter).getTime();

        // Calculate the difference in milliseconds
        const difference = Math.abs(d2 - d1);

        // Convert the difference to months
        const months = difference / (1000 * 60 * 60 * 24 * 30);

        // Round to the nearest integer and return
        return Math.round(months);
    }, [startDateFilter, endDateFilter]);

    /**
     * Used by the << and >> buttons to move back and forward
     * between time periods.
     *
     * Eg: operators select a range, like 1 year and then navigate
     * "in time" in 1 year slices of data.
     */
    const updateRange = useCallback(
        (months: number) => {
            updateMapFilterState((d) => {
                d.emissions.startDateFilter = new Date(
                    new Date().setMonth(new Date().getMonth() - months),
                ).toISOString();
                d.emissions.endDateFilter = new Date().toISOString();
            });
        },
        [updateMapFilterState],
    );

    const moveNPeriods = useCallback(
        (periodsToMove: number) => {
            const d1 = new Date(startDateFilter).getTime();
            const d2 = new Date(endDateFilter).getTime();
            const difference = Math.abs(d2 - d1);
            updateMapFilterState((d) => {
                d.emissions.startDateFilter = new Date(
                    d1 + periodsToMove * difference,
                ).toISOString();
                d.emissions.endDateFilter = new Date(
                    d2 + periodsToMove * difference,
                ).toISOString();
            });
        },
        [startDateFilter, endDateFilter, updateMapFilterState],
    );

    return (
        <div className="flex">
            <Button
                className="h-8 w-8 rounded-l"
                onClick={() => moveNPeriods(-1)}
            >
                <FontAwesomeIcon icon={faChevronDoubleLeft} className="w-4" />
            </Button>
            <Button
                className="px-3 border-0 border-y hidden lg:block"
                onClick={() => updateRange(1)}
                active={monthsBetweenDates == 1}
            >
                1m
            </Button>
            <Button
                className="px-3 border-0 border-y hidden lg:block"
                onClick={() => updateRange(3)}
                active={monthsBetweenDates == 3}
            >
                3m
            </Button>
            <Button
                className="px-3 border-0 border-y hidden lg:block"
                onClick={() => updateRange(6)}
                active={monthsBetweenDates == 6}
            >
                6m
            </Button>
            <Button
                className="px-3 border-0 border-y hidden lg:block"
                onClick={() => updateRange(12)}
                active={monthsBetweenDates == 12}
            >
                1y
            </Button>
            <div className="group flex">
                <ValidatingDateInput
                    className="border-0 lg:border-l border-y"
                    value={startDateFilter}
                    onChange={handleChangeStartDate}
                />
                <div className="h-8 px-2 flex items-center justify-center border-y border-ae-gray-250 bg-white group-hover:bg-ae-gray-100">
                    <FontAwesomeIcon icon={faArrowRight} className="w-4" />
                </div>
                <ValidatingDateInput
                    className="border-0 border-y"
                    value={endDateFilter}
                    onChange={handleChangeEndDate}
                />
            </div>
            <Button
                className="h-8 w-8 rounded-r"
                onClick={() => moveNPeriods(1)}
                disabled={new Date(endDateFilter) > new Date()}
            >
                <FontAwesomeIcon icon={faChevronDoubleRight} className="w-4" />
            </Button>
        </div>
    );
};
