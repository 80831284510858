import { faUpload, faArrowsRotate } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchInput } from "../ui/Inputs";
import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueries } from "@tanstack/react-query";
import { useGeoDataApi } from "../hooks";
import { FilterGroup, FilterItem } from "../apiClient/generated";
import { readGeoFileAsGeoJson } from "../utils/geopatialUtils";
import { multiPolygon } from "@turf/helpers";
import {
    faChevronDown,
    faChevronRight,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { Combobox } from "@headlessui/react";
import {
    useFloating,
    offset,
    shift,
    flip,
    size,
    autoUpdate,
} from "@floating-ui/react";
import UploadDropdown from "./UploadDropdown";
import { SecondaryButton } from "../ui/Buttons";
import { Control, useController } from "react-hook-form";

const LoadingIcon = () => (
    <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faArrowsRotate} className="w-4 animate-spin" />
    </div>
);

interface CheckboxItemProps {
    item: FilterItem;
    selected?: number[];
    onSelect: (selected: number[]) => void;
}

const CheckboxItem = ({ item, selected, onSelect }: CheckboxItemProps) => {
    const isChecked = selected?.some((i) => item.id === i);
    const handleClick = () => {
        if (isChecked) {
            onSelect(selected.filter((i) => i !== item.id));
        } else {
            onSelect([...(selected || []), item.id]);
        }
    };

    return (
        <label
            className="flex items-center justify-start gap-2 h-8"
            key={item.name}
        >
            <input
                type="checkbox"
                className="rounded text-ae-blue-550"
                defaultChecked={isChecked}
                onClick={handleClick}
            />
            <span>{item.name}</span>
        </label>
    );
};

interface GeoFilterItemsProps {
    group: FilterGroup;
    items?: FilterItem[];
    selected?: number[];
    onSelect: (selection: number[]) => void;
    search?: string;
}

export const GeoFilterItems = (props: GeoFilterItemsProps) => {
    const { selected, search, items } = props;
    const filterItems = useMemo(() => {
        if (!props.items) {
            return [];
        }

        return items
            .filter(
                (item) =>
                    // Don't show items not included in search
                    item.name.toLowerCase().includes(search) ||
                    // Always show items that are selected
                    (selected || []).includes(item.id),
            )
            .toSorted((a, b) => {
                return a.name.localeCompare(b.name);
            });
    }, [items, selected, search]);

    return (
        <div className="overflow-y-scroll px-2 max-h-60">
            {filterItems.map((item) => (
                <CheckboxItem
                    key={item.id}
                    item={item}
                    onSelect={props.onSelect}
                    selected={props.selected}
                />
            ))}
        </div>
    );
};

interface GeoFilterSelectProps {
    customShape?: string;
    selected?: number[];
    onSelect: (selection: number[]) => void;
    setCustomShape?: (shape?: string) => void;
}

export const GeoFilterSelect = (props: GeoFilterSelectProps) => {
    const { selected } = props;
    const [search, setSearch] = useState("");
    const apiClient = useGeoDataApi();
    const [selectedGroup, setSelectedGroup] = useState<number>();

    const setLocationFilter = (files: FileList) => {
        // Load shape from upload
        readGeoFileAsGeoJson({
            file: files[0],
            callback: (data, error) => {
                if (error) {
                    alert("Failed to load polygon.");
                    return;
                }
                // Extracts all polygons from FeatureCollection
                // and creates MultiPolygon for backend filtering.
                const polygons = [];
                data.features.forEach((f) => {
                    if (f.geometry.type == "Polygon") {
                        polygons.push(f.geometry.coordinates);
                    } else if (f.geometry.type == "MultiPolygon") {
                        f.geometry.coordinates.forEach((p) => polygons.push(p));
                    }
                });

                // If empty, don't filter
                if (polygons.length < 1) {
                    alert("No polygons found inside file.");
                }

                // Create polygon and filter
                const filterPolygon = multiPolygon(polygons);
                props.setCustomShape(JSON.stringify(filterPolygon.geometry));
            },
        });
    };

    // Query filter groups
    const groupsQuery = useQuery({
        queryKey: ["geoFilterGroups"],
        queryFn: async () => {
            const response = await apiClient.geodataGroupsList({
                pageSize: 10,
            });
            return response.results;
        },
        staleTime: Infinity,
    });

    // Open first group automatically
    useEffect(() => {
        if (groupsQuery.data && groupsQuery.data.length > 0) {
            setSelectedGroup(0);
        }
    }, [groupsQuery.data]);

    // Second set of queries to get details for each group
    const groupDetailsQueries = useQueries({
        queries:
            groupsQuery.data?.map((group) => ({
                queryKey: ["filterItem", group.id],
                queryFn: async () => {
                    const response = await apiClient.geodataItemsList({
                        pageSize: 200,
                        group: group.id,
                    });
                    return response.results;
                },
                staleTime: Infinity,
            })) || [],
    });

    const isLoading =
        groupsQuery.isLoading ||
        !groupsQuery.data ||
        groupDetailsQueries
            .map((q) => q.isLoading || q.data === undefined)
            .some((v) => v);

    return (
        <div className="h-72">
            <div className="flex gap-2">
                <SearchInput
                    containerClassName="w-full"
                    value={search}
                    onChange={setSearch}
                    placeholder="Search"
                />
                {props.setCustomShape && (
                    <>
                        <label
                            htmlFor="locationFilter"
                            title="Use a KML/GeoJSON"
                            className="border rounded border-ae-blue-900 px-4 flex items-center justify-center cursor-pointer hover:bg-ae-slate-300"
                        >
                            <FontAwesomeIcon icon={faUpload} className="w-4" />
                        </label>
                        <input
                            id="locationFilter"
                            type="file"
                            className="hidden"
                            onChange={(e) => setLocationFilter(e.target.files)}
                            accept={".geojson,.kml"}
                        />
                    </>
                )}
            </div>
            <div className="my-2" />
            {props.customShape ? (
                <div className="p-1 font-normal text-sm text-ae-blue-900">
                    <p>Filtering using GeoJSON/KML filter.</p>
                    <hr className="my-1" />
                    <button
                        className="w-full flex justify-center font-bold"
                        onClick={() => props.setCustomShape(undefined)}
                    >
                        Clear filter
                    </button>
                </div>
            ) : (
                <>
                    {isLoading && <LoadingIcon />}
                    {!isLoading && (
                        <div className="flex gap-10 text-sm my-4">
                            <div className="w-2/5">
                                {groupsQuery.data.map((group, idx) => {
                                    let selectedInGroup = 0;
                                    if (
                                        groupDetailsQueries[idx] &&
                                        groupDetailsQueries[idx].data
                                    ) {
                                        selectedInGroup =
                                            groupDetailsQueries[
                                                idx
                                            ]?.data.filter((item) =>
                                                (selected || []).includes(
                                                    item.id,
                                                ),
                                            ).length ?? 0;
                                    }
                                    return (
                                        <button
                                            key={idx}
                                            className={`
                                                w-full h-8 px-4 flex items-center justify-between
                                                ${
                                                    idx === selectedGroup &&
                                                    "text-ae-blue-550 bg-ae-blue-40"
                                                }
                                                ${
                                                    selectedInGroup > 0 &&
                                                    "font-bold"
                                                }
                                            `}
                                            onClick={() =>
                                                setSelectedGroup(idx)
                                            }
                                        >
                                            {group.name}
                                            {selectedInGroup
                                                ? ` (${selectedInGroup})`
                                                : ""}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className="w-2"
                                            />
                                        </button>
                                    );
                                })}
                            </div>
                            <div className="w-3/5 flex flex-col">
                                {groupDetailsQueries[selectedGroup] ? (
                                    <GeoFilterItems
                                        group={groupsQuery.data[selectedGroup]}
                                        items={
                                            groupDetailsQueries[selectedGroup]
                                                .data
                                        }
                                        onSelect={props.onSelect}
                                        selected={props.selected}
                                        search={search}
                                    />
                                ) : (
                                    <LoadingIcon />
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export const GeoFilterSelectDropdown = (props: GeoFilterSelectProps) => {
    const { selected } = props;
    const [search, setSearch] = useState("");
    const apiClient = useGeoDataApi();
    const [selectedGroup, setSelectedGroup] = useState<number | "upload">();
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "bottom-start",
        middleware: [
            offset({
                mainAxis: 4,
            }),
            shift(),
            flip(),
            // Set the floating element size to the container size.
            // This is cool!
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const setLocationFilter = (files: FileList) => {
        // Load shape from upload
        readGeoFileAsGeoJson({
            file: files[0],
            callback: (data, error) => {
                if (error) {
                    alert("Failed to load polygon.");
                    return;
                }
                // Extracts all polygons from FeatureCollection
                // and creates MultiPolygon for backend filtering.
                const polygons = [];
                data.features.forEach((f) => {
                    if (f.geometry.type == "Polygon") {
                        polygons.push(f.geometry.coordinates);
                    } else if (f.geometry.type == "MultiPolygon") {
                        f.geometry.coordinates.forEach((p) => polygons.push(p));
                    }
                });

                // If empty, don't filter
                if (polygons.length < 1) {
                    alert("No polygons found inside file.");
                }

                // Create polygon and filter
                const filterPolygon = multiPolygon(polygons);
                props.setCustomShape(JSON.stringify(filterPolygon.geometry));
            },
        });
    };

    // Query filter groups
    const groupsQuery = useQuery({
        queryKey: ["geoFilterGroups"],
        queryFn: async () => {
            const response = await apiClient.geodataGroupsList({
                pageSize: 10,
            });
            return response.results;
        },
        staleTime: Infinity,
    });

    // Open first group automatically
    useEffect(() => {
        if (groupsQuery.data && groupsQuery.data.length > 0) {
            setSelectedGroup(0);
        }
    }, [groupsQuery.data]);

    // Second set of queries to get details for each group
    const groupDetailsQueries = useQueries({
        queries:
            groupsQuery.data?.map((group) => ({
                queryKey: ["filterItem", group.id],
                queryFn: async () => {
                    const response = await apiClient.geodataItemsList({
                        pageSize: 200,
                        group: group.id,
                    });
                    return response.results;
                },
                staleTime: Infinity,
            })) || [],
    });

    const isLoading =
        groupsQuery.isLoading ||
        !groupsQuery.data ||
        groupDetailsQueries
            .map((q) => q.isLoading || q.data === undefined)
            .some((v) => v);

    return (
        <Combobox value={props.selected} onChange={props.onSelect} multiple>
            <div
                ref={refs.setReference}
                className="text-sm rounded flex justify-between border border-ae-gray-250 bg-white items-center px-2 focus-within:ring-2 ring-ae-blue-550"
            >
                <div className="flex flex-wrap gap-1 py-1 w-full">
                    {props.selected?.map((id) => {
                        let itemName = "";
                        groupDetailsQueries.forEach((groupData) => {
                            const item = groupData.data.find(
                                (i) => i.id === id,
                            );
                            if (item) {
                                itemName = item.name;
                                return;
                            }
                        });
                        return (
                            <button
                                type="button"
                                className="flex text-ae-blue-650 border border-ae-blue-80 bg-ae-blue-50 items-center rounded h-5 px-1 capitalize gap-1  whitespace-nowrap"
                                onClick={() => {
                                    props.onSelect(
                                        props.selected.filter((i) => i !== id),
                                    );
                                }}
                            >
                                {itemName}
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="w-3 h-3"
                                />
                            </button>
                        );
                    })}
                    {props.customShape && (
                        <button
                            type="button"
                            className="flex text-ae-blue-650 border border-ae-blue-80 bg-ae-blue-50 items-center rounded h-5 px-1 capitalize gap-1  whitespace-nowrap"
                            onClick={() => props.setCustomShape(undefined)}
                        >
                            Custom file
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="w-3 h-3"
                            />
                        </button>
                    )}
                    <Combobox.Input
                        className="border-0 p-0 focus:ring-0 text-sm flex-grow"
                        onChange={(event) => setSearch(event.target.value)}
                        placeholder={
                            !props.selected || props.selected.length === 0
                                ? "All locations"
                                : undefined
                        }
                    />
                </div>
                <Combobox.Button className="px-2" type="button">
                    <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3" />
                </Combobox.Button>
            </div>
            <Combobox.Options
                ref={refs.setFloating}
                style={floatingStyles}
                className="bg-white rounded drop-shadow-lg z-50"
            >
                {isLoading && <LoadingIcon />}
                {!isLoading && (
                    <div className="flex gap-6 text-sm p-2">
                        <div className="w-2/5">
                            {groupsQuery.data.map((group, idx) => {
                                let selectedInGroup = 0;
                                if (
                                    groupDetailsQueries[idx] &&
                                    groupDetailsQueries[idx].data
                                ) {
                                    selectedInGroup =
                                        groupDetailsQueries[idx]?.data.filter(
                                            (item) =>
                                                (selected || []).includes(
                                                    item.id,
                                                ),
                                        ).length ?? 0;
                                }
                                return (
                                    <button
                                        key={idx}
                                        type="button"
                                        className={`
                                                w-full h-8 px-4 flex items-center justify-between
                                                ${
                                                    idx === selectedGroup &&
                                                    "text-ae-blue-550 bg-ae-blue-40"
                                                }
                                                ${
                                                    selectedInGroup > 0 &&
                                                    "font-bold"
                                                }
                                            `}
                                        onClick={() => setSelectedGroup(idx)}
                                    >
                                        {group.name}
                                        {selectedInGroup
                                            ? ` (${selectedInGroup})`
                                            : ""}
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className="w-2"
                                        />
                                    </button>
                                );
                            })}
                            <button
                                type="button"
                                className={`
                                        w-full h-8 px-4 flex items-center justify-between
                                        ${
                                            "upload" === selectedGroup &&
                                            "text-ae-blue-550 bg-ae-blue-40"
                                        }
                                        ${props.customShape && "font-bold"}
                                    `}
                                onClick={() => setSelectedGroup("upload")}
                            >
                                Use a custom area
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className="w-2"
                                />
                            </button>
                        </div>
                        <div className="w-3/5 flex flex-col">
                            {selectedGroup !== "upload" ? (
                                groupDetailsQueries[selectedGroup] ? (
                                    <GeoFilterItems
                                        group={groupsQuery.data[selectedGroup]}
                                        items={
                                            groupDetailsQueries[selectedGroup]
                                                .data
                                        }
                                        onSelect={props.onSelect}
                                        selected={props.selected}
                                        search={search}
                                    />
                                ) : (
                                    <LoadingIcon />
                                )
                            ) : (
                                <div className="w-full h-full flex items-center justify-center">
                                    {props.customShape ? (
                                        <div className="w-full h-full text-left ">
                                            <p className="mb-2">
                                                Filtering using GeoJSON/KML
                                                filter.
                                            </p>
                                            <SecondaryButton
                                                onClick={() =>
                                                    props.setCustomShape(
                                                        undefined,
                                                    )
                                                }
                                            >
                                                Remove custom filter
                                            </SecondaryButton>
                                        </div>
                                    ) : (
                                        <UploadDropdown
                                            accept=".geojson,.kml"
                                            onChange={setLocationFilter}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Combobox.Options>
        </Combobox>
    );
};

interface GeoFilterFormFieldProps {
    locationPresetName: string;
    locationWhitinName: string;
    control: Control<any>;
}

export const GeoFilterFormField = ({
    locationPresetName,
    locationWhitinName,
    control,
}: GeoFilterFormFieldProps) => {
    const { field: locationPreset } = useController({
        name: locationPresetName,
        control,
    });
    const { field: locationWhitin } = useController({
        name: locationWhitinName,
        control,
    });
    return (
        <GeoFilterSelectDropdown
            selected={locationPreset.value}
            onSelect={(v) => locationPreset.onChange(v)}
            customShape={locationWhitin.value}
            setCustomShape={(v) => locationWhitin.onChange(v)}
        />
    );
};
