/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmissionRecordStatsResponse,
  EmissionRecordUpdate,
  EmissionRecordUpdateRequest,
  EmissionRecordView,
  PaginatedEmissionRecordViewList,
  PatchedEmissionRecordUpdateRequest,
  ReportRespose,
} from '../models/index';
import {
    EmissionRecordStatsResponseFromJSON,
    EmissionRecordStatsResponseToJSON,
    EmissionRecordUpdateFromJSON,
    EmissionRecordUpdateToJSON,
    EmissionRecordUpdateRequestFromJSON,
    EmissionRecordUpdateRequestToJSON,
    EmissionRecordViewFromJSON,
    EmissionRecordViewToJSON,
    PaginatedEmissionRecordViewListFromJSON,
    PaginatedEmissionRecordViewListToJSON,
    PatchedEmissionRecordUpdateRequestFromJSON,
    PatchedEmissionRecordUpdateRequestToJSON,
    ReportResposeFromJSON,
    ReportResposeToJSON,
} from '../models/index';

export interface EmissionRecordsEmissionRecordStatsRetrieveRequest {
    assignedTo?: Array<string>;
    concentrationMax?: number;
    concentrationMin?: number;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<EmissionRecordsEmissionRecordStatsRetrieveDataSourceEnum>;
    detectedRateMax?: number;
    detectedRateMin?: number;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    emissionGlobalStatus?: Array<EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum>;
    emissionStatus?: Array<EmissionRecordsEmissionRecordStatsRetrieveEmissionStatusEnum>;
    eventStatus?: Array<EmissionRecordsEmissionRecordStatsRetrieveEventStatusEnum>;
    hasEvents?: boolean;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    plume?: boolean;
    provider?: Array<string>;
    relatedEvent?: string;
    search?: string;
    secondaryDataSource?: Array<string>;
}

export interface EmissionRecordsGenerateCsvReportCreateRequest {
    assignedTo?: Array<string>;
    concentrationMax?: number;
    concentrationMin?: number;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<EmissionRecordsGenerateCsvReportCreateDataSourceEnum>;
    detectedRateMax?: number;
    detectedRateMin?: number;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    emissionGlobalStatus?: Array<EmissionRecordsGenerateCsvReportCreateEmissionGlobalStatusEnum>;
    emissionStatus?: Array<EmissionRecordsGenerateCsvReportCreateEmissionStatusEnum>;
    eventStatus?: Array<EmissionRecordsGenerateCsvReportCreateEventStatusEnum>;
    hasEvents?: boolean;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    plume?: boolean;
    provider?: Array<string>;
    relatedEvent?: string;
    search?: string;
    secondaryDataSource?: Array<string>;
}

export interface EmissionRecordsGetNearbyEventsListRequest {
    id: string;
    assignedTo?: Array<string>;
    concentrationMax?: number;
    concentrationMin?: number;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<EmissionRecordsGetNearbyEventsListDataSourceEnum>;
    detectedRateMax?: number;
    detectedRateMin?: number;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    emissionGlobalStatus?: Array<EmissionRecordsGetNearbyEventsListEmissionGlobalStatusEnum>;
    emissionStatus?: Array<EmissionRecordsGetNearbyEventsListEmissionStatusEnum>;
    eventStatus?: Array<EmissionRecordsGetNearbyEventsListEventStatusEnum>;
    hasEvents?: boolean;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    plume?: boolean;
    provider?: Array<string>;
    relatedEvent?: string;
    search?: string;
    secondaryDataSource?: Array<string>;
}

export interface EmissionRecordsListRequest {
    assignedTo?: Array<string>;
    concentrationMax?: number;
    concentrationMin?: number;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<EmissionRecordsListDataSourceEnum>;
    detectedRateMax?: number;
    detectedRateMin?: number;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    emissionGlobalStatus?: Array<EmissionRecordsListEmissionGlobalStatusEnum>;
    emissionStatus?: Array<EmissionRecordsListEmissionStatusEnum>;
    eventStatus?: Array<EmissionRecordsListEventStatusEnum>;
    hasEvents?: boolean;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    plume?: boolean;
    provider?: Array<string>;
    relatedEvent?: string;
    search?: string;
    secondaryDataSource?: Array<string>;
}

export interface EmissionRecordsPartialUpdateRequest {
    id: string;
    patchedEmissionRecordUpdateRequest?: PatchedEmissionRecordUpdateRequest;
}

export interface EmissionRecordsRetrieveRequest {
    id: string;
}

export interface EmissionRecordsUpdateRequest {
    id: string;
    emissionRecordUpdateRequest: EmissionRecordUpdateRequest;
}

/**
 * 
 */
export class EmissionRecordsApi extends runtime.BaseAPI {

    /**
     * Returns statistics to de displayed in a dashboard style.
     */
    async emissionRecordsEmissionRecordStatsRetrieveRaw(requestParameters: EmissionRecordsEmissionRecordStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionRecordStatsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['assignedTo'] != null) {
            queryParameters['assigned_to'] = requestParameters['assignedTo'];
        }

        if (requestParameters['concentrationMax'] != null) {
            queryParameters['concentration_max'] = requestParameters['concentrationMax'];
        }

        if (requestParameters['concentrationMin'] != null) {
            queryParameters['concentration_min'] = requestParameters['concentrationMin'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectedRateMax'] != null) {
            queryParameters['detected_rate_max'] = requestParameters['detectedRateMax'];
        }

        if (requestParameters['detectedRateMin'] != null) {
            queryParameters['detected_rate_min'] = requestParameters['detectedRateMin'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['emissionGlobalStatus'] != null) {
            queryParameters['emission_global_status'] = requestParameters['emissionGlobalStatus'];
        }

        if (requestParameters['emissionStatus'] != null) {
            queryParameters['emission_status'] = requestParameters['emissionStatus'];
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['hasEvents'] != null) {
            queryParameters['has_events'] = requestParameters['hasEvents'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['plume'] != null) {
            queryParameters['plume'] = requestParameters['plume'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['relatedEvent'] != null) {
            queryParameters['related_event'] = requestParameters['relatedEvent'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/emission_record_stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionRecordStatsResponseFromJSON(jsonValue));
    }

    /**
     * Returns statistics to de displayed in a dashboard style.
     */
    async emissionRecordsEmissionRecordStatsRetrieve(requestParameters: EmissionRecordsEmissionRecordStatsRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionRecordStatsResponse> {
        const response = await this.emissionRecordsEmissionRecordStatsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * If the user has permissions, triggers report generation.
     */
    async emissionRecordsGenerateCsvReportCreateRaw(requestParameters: EmissionRecordsGenerateCsvReportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportRespose>> {
        const queryParameters: any = {};

        if (requestParameters['assignedTo'] != null) {
            queryParameters['assigned_to'] = requestParameters['assignedTo'];
        }

        if (requestParameters['concentrationMax'] != null) {
            queryParameters['concentration_max'] = requestParameters['concentrationMax'];
        }

        if (requestParameters['concentrationMin'] != null) {
            queryParameters['concentration_min'] = requestParameters['concentrationMin'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectedRateMax'] != null) {
            queryParameters['detected_rate_max'] = requestParameters['detectedRateMax'];
        }

        if (requestParameters['detectedRateMin'] != null) {
            queryParameters['detected_rate_min'] = requestParameters['detectedRateMin'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['emissionGlobalStatus'] != null) {
            queryParameters['emission_global_status'] = requestParameters['emissionGlobalStatus'];
        }

        if (requestParameters['emissionStatus'] != null) {
            queryParameters['emission_status'] = requestParameters['emissionStatus'];
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['hasEvents'] != null) {
            queryParameters['has_events'] = requestParameters['hasEvents'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['plume'] != null) {
            queryParameters['plume'] = requestParameters['plume'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['relatedEvent'] != null) {
            queryParameters['related_event'] = requestParameters['relatedEvent'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/generate_csv_report/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportResposeFromJSON(jsonValue));
    }

    /**
     * If the user has permissions, triggers report generation.
     */
    async emissionRecordsGenerateCsvReportCreate(requestParameters: EmissionRecordsGenerateCsvReportCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportRespose> {
        const response = await this.emissionRecordsGenerateCsvReportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of similar emissions that are already linked to an event.  TODO: convert this into a custom filter instead of a custom action.
     */
    async emissionRecordsGetNearbyEventsListRaw(requestParameters: EmissionRecordsGetNearbyEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmissionRecordView>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissionRecordsGetNearbyEventsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['assignedTo'] != null) {
            queryParameters['assigned_to'] = requestParameters['assignedTo'];
        }

        if (requestParameters['concentrationMax'] != null) {
            queryParameters['concentration_max'] = requestParameters['concentrationMax'];
        }

        if (requestParameters['concentrationMin'] != null) {
            queryParameters['concentration_min'] = requestParameters['concentrationMin'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectedRateMax'] != null) {
            queryParameters['detected_rate_max'] = requestParameters['detectedRateMax'];
        }

        if (requestParameters['detectedRateMin'] != null) {
            queryParameters['detected_rate_min'] = requestParameters['detectedRateMin'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['emissionGlobalStatus'] != null) {
            queryParameters['emission_global_status'] = requestParameters['emissionGlobalStatus'];
        }

        if (requestParameters['emissionStatus'] != null) {
            queryParameters['emission_status'] = requestParameters['emissionStatus'];
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['hasEvents'] != null) {
            queryParameters['has_events'] = requestParameters['hasEvents'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['plume'] != null) {
            queryParameters['plume'] = requestParameters['plume'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['relatedEvent'] != null) {
            queryParameters['related_event'] = requestParameters['relatedEvent'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/{id}/get_nearby_events/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmissionRecordViewFromJSON));
    }

    /**
     * Retrieves a list of similar emissions that are already linked to an event.  TODO: convert this into a custom filter instead of a custom action.
     */
    async emissionRecordsGetNearbyEventsList(requestParameters: EmissionRecordsGetNearbyEventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmissionRecordView>> {
        const response = await this.emissionRecordsGetNearbyEventsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsListRaw(requestParameters: EmissionRecordsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEmissionRecordViewList>> {
        const queryParameters: any = {};

        if (requestParameters['assignedTo'] != null) {
            queryParameters['assigned_to'] = requestParameters['assignedTo'];
        }

        if (requestParameters['concentrationMax'] != null) {
            queryParameters['concentration_max'] = requestParameters['concentrationMax'];
        }

        if (requestParameters['concentrationMin'] != null) {
            queryParameters['concentration_min'] = requestParameters['concentrationMin'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectedRateMax'] != null) {
            queryParameters['detected_rate_max'] = requestParameters['detectedRateMax'];
        }

        if (requestParameters['detectedRateMin'] != null) {
            queryParameters['detected_rate_min'] = requestParameters['detectedRateMin'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['emissionGlobalStatus'] != null) {
            queryParameters['emission_global_status'] = requestParameters['emissionGlobalStatus'];
        }

        if (requestParameters['emissionStatus'] != null) {
            queryParameters['emission_status'] = requestParameters['emissionStatus'];
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['hasEvents'] != null) {
            queryParameters['has_events'] = requestParameters['hasEvents'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['plume'] != null) {
            queryParameters['plume'] = requestParameters['plume'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['relatedEvent'] != null) {
            queryParameters['related_event'] = requestParameters['relatedEvent'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEmissionRecordViewListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsList(requestParameters: EmissionRecordsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEmissionRecordViewList> {
        const response = await this.emissionRecordsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsPartialUpdateRaw(requestParameters: EmissionRecordsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionRecordUpdate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissionRecordsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEmissionRecordUpdateRequestToJSON(requestParameters['patchedEmissionRecordUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionRecordUpdateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsPartialUpdate(requestParameters: EmissionRecordsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionRecordUpdate> {
        const response = await this.emissionRecordsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsRetrieveRaw(requestParameters: EmissionRecordsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionRecordView>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissionRecordsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionRecordViewFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsRetrieve(requestParameters: EmissionRecordsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionRecordView> {
        const response = await this.emissionRecordsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsUpdateRaw(requestParameters: EmissionRecordsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionRecordUpdate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissionRecordsUpdate().'
            );
        }

        if (requestParameters['emissionRecordUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'emissionRecordUpdateRequest',
                'Required parameter "emissionRecordUpdateRequest" was null or undefined when calling emissionRecordsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emission-records/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmissionRecordUpdateRequestToJSON(requestParameters['emissionRecordUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionRecordUpdateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve emission information
     */
    async emissionRecordsUpdate(requestParameters: EmissionRecordsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionRecordUpdate> {
        const response = await this.emissionRecordsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const EmissionRecordsEmissionRecordStatsRetrieveDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type EmissionRecordsEmissionRecordStatsRetrieveDataSourceEnum = typeof EmissionRecordsEmissionRecordStatsRetrieveDataSourceEnum[keyof typeof EmissionRecordsEmissionRecordStatsRetrieveDataSourceEnum];
/**
 * @export
 */
export const EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum = {
    Complete: 'COMPLETE',
    InProgress: 'IN_PROGRESS',
    New: 'NEW',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum = typeof EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum[keyof typeof EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum];
/**
 * @export
 */
export const EmissionRecordsEmissionRecordStatsRetrieveEmissionStatusEnum = {
    Accepted: 'ACCEPTED',
    Matched: 'MATCHED',
    NearMatch: 'NEAR_MATCH',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsEmissionRecordStatsRetrieveEmissionStatusEnum = typeof EmissionRecordsEmissionRecordStatsRetrieveEmissionStatusEnum[keyof typeof EmissionRecordsEmissionRecordStatsRetrieveEmissionStatusEnum];
/**
 * @export
 */
export const EmissionRecordsEmissionRecordStatsRetrieveEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type EmissionRecordsEmissionRecordStatsRetrieveEventStatusEnum = typeof EmissionRecordsEmissionRecordStatsRetrieveEventStatusEnum[keyof typeof EmissionRecordsEmissionRecordStatsRetrieveEventStatusEnum];
/**
 * @export
 */
export const EmissionRecordsGenerateCsvReportCreateDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type EmissionRecordsGenerateCsvReportCreateDataSourceEnum = typeof EmissionRecordsGenerateCsvReportCreateDataSourceEnum[keyof typeof EmissionRecordsGenerateCsvReportCreateDataSourceEnum];
/**
 * @export
 */
export const EmissionRecordsGenerateCsvReportCreateEmissionGlobalStatusEnum = {
    Complete: 'COMPLETE',
    InProgress: 'IN_PROGRESS',
    New: 'NEW',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsGenerateCsvReportCreateEmissionGlobalStatusEnum = typeof EmissionRecordsGenerateCsvReportCreateEmissionGlobalStatusEnum[keyof typeof EmissionRecordsGenerateCsvReportCreateEmissionGlobalStatusEnum];
/**
 * @export
 */
export const EmissionRecordsGenerateCsvReportCreateEmissionStatusEnum = {
    Accepted: 'ACCEPTED',
    Matched: 'MATCHED',
    NearMatch: 'NEAR_MATCH',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsGenerateCsvReportCreateEmissionStatusEnum = typeof EmissionRecordsGenerateCsvReportCreateEmissionStatusEnum[keyof typeof EmissionRecordsGenerateCsvReportCreateEmissionStatusEnum];
/**
 * @export
 */
export const EmissionRecordsGenerateCsvReportCreateEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type EmissionRecordsGenerateCsvReportCreateEventStatusEnum = typeof EmissionRecordsGenerateCsvReportCreateEventStatusEnum[keyof typeof EmissionRecordsGenerateCsvReportCreateEventStatusEnum];
/**
 * @export
 */
export const EmissionRecordsGetNearbyEventsListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type EmissionRecordsGetNearbyEventsListDataSourceEnum = typeof EmissionRecordsGetNearbyEventsListDataSourceEnum[keyof typeof EmissionRecordsGetNearbyEventsListDataSourceEnum];
/**
 * @export
 */
export const EmissionRecordsGetNearbyEventsListEmissionGlobalStatusEnum = {
    Complete: 'COMPLETE',
    InProgress: 'IN_PROGRESS',
    New: 'NEW',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsGetNearbyEventsListEmissionGlobalStatusEnum = typeof EmissionRecordsGetNearbyEventsListEmissionGlobalStatusEnum[keyof typeof EmissionRecordsGetNearbyEventsListEmissionGlobalStatusEnum];
/**
 * @export
 */
export const EmissionRecordsGetNearbyEventsListEmissionStatusEnum = {
    Accepted: 'ACCEPTED',
    Matched: 'MATCHED',
    NearMatch: 'NEAR_MATCH',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsGetNearbyEventsListEmissionStatusEnum = typeof EmissionRecordsGetNearbyEventsListEmissionStatusEnum[keyof typeof EmissionRecordsGetNearbyEventsListEmissionStatusEnum];
/**
 * @export
 */
export const EmissionRecordsGetNearbyEventsListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type EmissionRecordsGetNearbyEventsListEventStatusEnum = typeof EmissionRecordsGetNearbyEventsListEventStatusEnum[keyof typeof EmissionRecordsGetNearbyEventsListEventStatusEnum];
/**
 * @export
 */
export const EmissionRecordsListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type EmissionRecordsListDataSourceEnum = typeof EmissionRecordsListDataSourceEnum[keyof typeof EmissionRecordsListDataSourceEnum];
/**
 * @export
 */
export const EmissionRecordsListEmissionGlobalStatusEnum = {
    Complete: 'COMPLETE',
    InProgress: 'IN_PROGRESS',
    New: 'NEW',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsListEmissionGlobalStatusEnum = typeof EmissionRecordsListEmissionGlobalStatusEnum[keyof typeof EmissionRecordsListEmissionGlobalStatusEnum];
/**
 * @export
 */
export const EmissionRecordsListEmissionStatusEnum = {
    Accepted: 'ACCEPTED',
    Matched: 'MATCHED',
    NearMatch: 'NEAR_MATCH',
    Rejected: 'REJECTED'
} as const;
export type EmissionRecordsListEmissionStatusEnum = typeof EmissionRecordsListEmissionStatusEnum[keyof typeof EmissionRecordsListEmissionStatusEnum];
/**
 * @export
 */
export const EmissionRecordsListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type EmissionRecordsListEventStatusEnum = typeof EmissionRecordsListEventStatusEnum[keyof typeof EmissionRecordsListEventStatusEnum];
