import { generateIconAtlas } from "./utils/helpers";

export const EMISSION_COLORS: {
    [key: string]: [number, number, number];
} = {
    thirdParty: [0, 124, 145],
    thirdPartyPublic: [0, 62, 73],
    operatorProvided: [154, 205, 50],
    epa: [106, 13, 173],
    epaPublic: [56, 0, 92],
};

export const CROSSHAIR_ICONS: {
    [key: string]: string;
} = Object.fromEntries(
    Object.entries(EMISSION_COLORS).map(([key, color]) => [
        key,
        generateIconAtlas(color),
    ]),
);

export const CROSSHAIR_ICONS_NO_CONTRAST: {
    [key: string]: string;
} = Object.fromEntries(
    Object.entries(EMISSION_COLORS).map(([key, color]) => [
        key,
        generateIconAtlas(color, true),
    ]),
);

export const EMISSION_BINS = [
    {
        label: "<100",
        lt: 100,
        size: 20,
    },
    {
        label: "100-10,000",
        gte: 100,
        lt: 1000,
        size: 35,
    },
    {
        label: ">10,000",
        gte: 10000,
        size: 50,
    },
];

export function getBinForRateOrConcentration(
    rate?: number,
    concentration?: number,
) {
    if (rate && (rate === 0 || isNaN(rate))) {
        return EMISSION_BINS[0];
    }

    if (rate > 0) {
        return (
            EMISSION_BINS.find((i) => {
                return (!i.lt || rate < i.lt) && (!i.gte || rate >= i.gte);
            }) || EMISSION_BINS[0]
        );
    }

    if (concentration > 10000) {
        return EMISSION_BINS[1];
    }

    return EMISSION_BINS[0];
}

export const MAP_ZOOM_SHOW_DETAILS = 13;
